import API from './../../utils/API'
import { mapGetters, mapActions } from "vuex";

export default {
  state: {
    inboxItems: [],
    readInboxItems: []
  },
  getters: {
    inboxItems: state => state.inboxItems,
    readInboxItems: state => state.readInboxItems,
  },
  mutations: {
    setInboxItems(state, payload) {
      state.inboxItems = payload;
    },
    setReadInboxItems(state, payload) {
      state.readInboxItems = payload;
    },
  },
  actions: {
    async getInboxItems({ commit, rootGetters }) {
      return new Promise(async (resolve, reject) => {
        let url =
          "inboxes?filters[is_deleted]=false&filters[$or][0][target_roles][$containsi]=public";

        if (rootGetters.currentUser && rootGetters.currentUser.role.name) {
          url += `&filters[$or][1][target_roles][$containsi]=${rootGetters.currentUser.role.name.toLowerCase()}`;
        }

        const { data, error } = await API.get(url);

        if (error) {
          return reject(error);
        } else {
          commit("setInboxItems", data.data);

          return resolve(data.data);
        }
      })
    },
    async getReadInboxItems({ commit, rootGetters }) {
      return new Promise(async (resolve, reject) => {
        try {
          let url = `users/${rootGetters.currentUser.id}?populate[0]=read_inboxes`;

          const { data, error } = await API.get(url);

          if (error) return reject();

          commit("setReadInboxItems", data.read_inboxes);

          return resolve();
        } catch (error) {
          return reject();
        }
      });
    },
    async updateReadInboxItems({ commit, state, rootGetters }, inbox) {
      return new Promise(async (resolve, reject) => {
        try {
          if (!state.readInboxItems.find((ri) => ri.id == inbox.id)) {
            let updatedReadInboxItems = [...state.readInboxItems];

            updatedReadInboxItems.push(inbox);

            let body = {
              read_inboxes: updatedReadInboxItems.map((ri) => ri.id),
            };

            await API.put(`users/${rootGetters.currentUser.id}`, body);

            commit("setReadInboxItems", updatedReadInboxItems);
          }

          return resolve();
        } catch (error) {
          return reject();
        }
      });
    },
  }
}