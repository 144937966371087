import axios from 'axios'
import store from './../store'
import Swal from "sweetalert2";
import SessionManager from './SessionManager'

export default class API {
	static getExternal(url, type) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = {
					method: "get",
					url: url,
					timeout: 2000000 
				};
				if (type == 'file') {
					opts.responseType = 'blob'
				};
				const response = await axios(opts);
				resolve(response);
			} catch (err) {
				console.error(JSON.stringify(err.response));
				reject(err);
			}
		})
	}

	static get(moduleName, type) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'get');
				if (type == 'file') {
					opts.responseType = 'blob'
				};
				const response = await axios(opts);
				resolve(response);
			} catch (err) {
				console.error(JSON.stringify(err.response));
				if (err.response && err.response.status == 401) {
					store.dispatch('authLogout')
				}
				reject(err);
			}
		})
	}

	static post(moduleName, data) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'post', data);
				const response = await axios(opts);
				resolve(response);
			} catch (err) {
				console.error(JSON.stringify(err.response));
				if (err.response && err.response.status == 401) {
					store.dispatch('authLogout')
				}
				reject(err);
			}
		})
	}

	static forgetPassword(moduleName, data) {
		return new Promise(async (resolve, reject) => {
			try {
				let opts = {
					method: 'post',
					url: `${this.apiUrl()}/${moduleName}`
				}

				if (data) {
					opts.data = data
				}
				const response = await axios(opts);
				resolve(response);
			} catch (err) {
				console.error(JSON.stringify(err.response));
				if (err.response && err.response.status == 401) {
					store.dispatch('authLogout')
				}
				reject(err);
			}
		})
	}

	static postForm(moduleName, data, timeout) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'post', data);
				opts.headers['Content-Type'] = 'multipart/form-data';

				if (timeout) {
					opts.timeout = timeout;
				}

				const response = await axios(opts);
				resolve(response)
			} catch (err) {
				if (err.response && err.response.status == 401) {
					store.dispatch('authLogout')
				}
				reject(err);
			}
		})
	}

	static verifyAuth(token){
		return new Promise(async (resolve, reject) => {
			try {
				let opts = {
					method: 'get',
					url: `${this.apiUrl()}/users/me`,
					headers: { 'Authorization': `Bearer ${token}` }
				}
				
				const response = await axios(opts);
				resolve(response);
			} catch (err) {
				if (err.response && err.response.status == 401) {
					store.dispatch('authLogout')
				}
				reject(err);
			}
		})
	}

	static put(moduleName, data) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'put', data);
				const response = await axios(opts);
				resolve(response)
			} catch (err) {
				if (err.response && err.response.status == 401) {
					store.dispatch('authLogout')
				}
				reject(err);
			}
		})
	}

	static delete(moduleName) {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await Swal.fire({
					title: 'Pasti untuk PADAM?',
					text: `Anda pasti untuk buang data ini?`,
					showCancelButton: true,
					cancelButtonText: 'Batal',
					confirmButtonText: 'Ya',
				}).then((result) => {
					return result.isConfirmed;
				})

				if (result) {
					const opts = this._opts(moduleName, 'delete');
					const response = await axios(opts);
					resolve({ data: response.data.data, toDelete: true })
				} else
					resolve({ data: null, toDelete: false })
			} catch (err) {
				if (err.response && err.response.status == 401) {
					store.dispatch('authLogout')
				}
				reject(err);
			}
		})
	}

	static _opts(moduleName, method, data) {
		let token = null
		if (store.getters.currentUserToken) token = store.getters.currentUserToken;

		const opts = {
			method: method,
			url: `${this.apiUrl()}/${moduleName}`,
			timeout: 60000
		};

		if (token) {
			opts.headers = {
				'Authorization': `Bearer ${token}`
			};
		}

		if (data) {
			opts.data = data
		};

		return opts;
	};

	static apiUrl() {
		return process.env.VUE_APP_API_URL;
		// return 'https://tabunghaji-api.inference.asia/api'
	};

	static getTinyMCE_APIKey() {
		return process.env.VUE_APP_TINYMCE_API_KEY;
	}
};