import store from './../store'

export default async (to, from, next) => {
    if(store.getters.currentUser) {
        const isAdmin = await store.dispatch('checkCurrentUserIsAdmin')

        if(isAdmin) next()
        else { //if non admin tried to access, then kill that session
            store.dispatch('authLogout')
            // next('/auth/login')
        }

        // if(store.getters.currentUser.role.name == 'Administrator')
        //     next()
        // else next('/auth/login')
    }
    else next('/auth/login')
}