import Vue from 'vue'
import VueRouter from 'vue-router'
import UserSessionCheck from './../utils/UserSessionCheck'
import UserAuthRequired from './../utils/UserAuthRequired'
import AdminAuthRequired from './../utils/AdminAuthRequired'
import RouteInit from '../utils/RouteInit'

Vue.use(VueRouter)

const routes = [
  /** Public paths */
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/public'),
    beforeEnter: RouteInit,
    children: [
      {
        path: '/',
        component: () => import('../views/public/Home.vue')
      },
      
      //-- Specific course only for public user ---
      {
        path: '/public-courses',
        component: () => import('../views/public/public-course'),
        redirect: '/public-courses/courses',
        children: [
          {
            path: 'courses',
            component: () => import('../views/public/public-course/Courses.vue'),
            meta: { title: 'Senarai Kursus' }
          },
          {
            path: 'courses/:courseid',
            component: () => import('../views/public/public-course/CourseView.vue'),
            meta: { title: 'Senarai Topik' }
          },
          {
            path: 'topics/:topicid',
            component: () => import('../views/public/public-course/TopicView.vue'),
            meta: { title: 'Topik' }
          },
        ]
      },
      //-- Specific course only for public user ---

      {
        path: '/about-e-bimbingan',
        component: () => import('../views/public/AboutELearning')
      },
      {
        path: '/contact-us',
        component: () => import('../views/public/ContactUs.vue')
      },
      {
        path: '/rujukan',
        component: () => import('../views/public/Galeri.vue')
      },
      // {
      //   path: '/pekta',
      //   component: () => import('../views/public/EmptyPage.vue')
      // },
      {
        path: '/tentang-kami',
        component: () => import('../views/public/EmptyPage.vue')
      },
      {
        path: '/article/:id',
        component: () => import('../views/public/ViewDetailsArtical.vue'),
        meta: { id: null }
      },
      {
        path: '/watch-stream-zoom/:id',
        component: () => import('../views/public/ViewStreamPageZoom.vue'),
        meta: { id: null }
      },
      {
        path: '/watch-stream/:id',
        component: () => import('../views/public/ViewStreamPage.vue'),
        meta: { id: null }
      },
      {
        path: '/view-pdf-file/:id',
        component: () => import('../views/public/ViewPDFFile.vue'),
        meta: { id: null }
      },
    ]
  },

  /** User paths */
  {
    path: '/user',
    component: () => import('../views/user'),
    beforeEnter: UserAuthRequired,
    children: [
      {
        path: 'courses',
        component: () => import('../views/user/Courses.vue'),
        meta: { title: 'Senarai Kursus' }
      },
      {
        path: 'courses/:courseid',
        component: () => import('../views/user/CourseView.vue'),
        meta: { title: 'Senarai Topik' }
      },
      {
        path: 'courses/:courseid/test',
        component: () => import('../views/user/TestView.vue'),
        meta: { title: 'Ujian' }
      },
      {
        path: 'topics/:topicid',
        component: () => import('../views/user/TopicView.vue'),
        meta: { title: 'Topik' }
      },
      {
        path: 'inbox',
        component: () => import('../views/user/Inbox.vue'),
        meta: { title: 'Peti Masuk' }
      },
      {
        path: 'profile/:tab?',
        component: () => import('../views/user/Profile.vue'),
        meta: { title: 'Profil' }
      },
    ]
  },

  /** Admin paths */
  {
    path: '/admin',
    component: () => import('../views/admin'),
    beforeEnter: AdminAuthRequired,
    children: [
      {
        path: 'dashboard',
        component: () => import('../views/admin/Dashbord.vue'),
        meta: { title: 'Dashboard' }
      },
      {
        path: 'pages',
        component: () => import('../views/admin/Pages.vue'),
        meta: { title: 'Halaman' }
      },
      {
        path: 'courses',
        component: () => import('../views/admin/Courses.vue'),
        meta: { title: 'Kursus' }
      },
      {
        path: 'courses/:courseid',
        component: () => import('../views/admin/CourseView.vue'),
        meta: { title: 'Kursus' }
      },
      {
        path: 'topics/:topicid',
        component: () => import('../views/admin/TopicView.vue'),
        meta: { title: 'Topik' }
      },
      {
        path: 'subtopics/:subtopicid',
        component: () => import('../views/admin/SubtopicView.vue'),
        meta: { title: 'Subtopik' }
      },
      {
        path: 'past-questions',
        component: () => import('../views/admin/PastQuestions.vue'),
        meta: { title: 'Senarai Soalan Lepas' }
      },
      {
        path: 'questions',
        component: () => import('../views/admin/Questions.vue'),
        meta: { title: 'Bank Soalan' }
      },
      {
        path: 'questions/:courseid',
        component: () => import('../views/admin/QuestionView.vue'),
        meta: { title: 'Senarai Soalan' }
      },
      {
        path: 'survey-questions',
        component: () => import('../views/admin/SurveyQuestions.vue'),
        meta: { title: 'Penilaian' }
      },
      {
        path: 'settings',
        component: () => import('../views/admin/Settings.vue'),
        meta: { title: 'Tetapan' }
      },
      {
        path: 'media',
        component: () => import('../views/admin/Media.vue'),
        meta: { title: 'Senarai Media' }
      },
      {
        path: 'files',
        component: () => import('../views/admin/Files.vue'),
        meta: { title: 'Senarai Fail' }
      },
      {
        path: 'users',
        component: () => import('../views/admin/Users.vue'),
        meta: { title: 'Pengguna' }
      },
      {
        path: 'list-admin',
        component: () => import('../views/admin/Admins.vue'),
        meta: { title: 'Senarai Pengguna' }
      }
    ]
  },

  {
    path: '/verify-auth',
    component: () => import('../views/VerifyAuth.vue')
  },

  /** Authentication paths */
  {
    path: '/auth',
    component: () => import('../views/auth'),
    redirect: '/auth/login',
    beforeEnter: UserSessionCheck,
    children: [
      {
        path: 'login',
        component: () => import('../views/auth/MainAuth.vue'),
        meta: { title: 'Daftar Masuk' }
      },
      {
        path: 'register',
        component: () => import('../views/auth/MainAuth.vue'),
        meta: { title: 'Daftar Baru' }
      },
      {
        path: 'forgot-password',
        component: () => import('../views/auth/MainAuth.vue'),
        meta: { title: 'Lupa Kata Laluan' }
      },
      {
        path: 'reset-password',
        component: () => import('../views/auth/MainAuth.vue'),
        meta: { title: 'Set Semula Kata Laluan' }
      },
      {
        path: 'first-time',
        component: () => import('../views/auth/MainAuth.vue'),
        meta: { title: 'Daftar Masuk' }
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//setting header title
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? `${to.meta.title} - e-Bimbingan Haji` : 'e-Bimbingan Haji'
  })
})

export default router
