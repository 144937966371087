// import cookies from 'vue-cookies'
import CryptoJS from 'crypto-js'

const SECRET_KEY = 'TABUNGHAJI@WEB'

//NOTES:: WE JUST SUDDENLY CHANGED COOKIES TO LOCALSTORAGE, SO BEAR IN MIND TO REFACTOR THE 'COOKIES' TERM SOONER.

const setCookiesSession = (_cookiesName, _cookiesValue) => {
    let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(_cookiesValue), SECRET_KEY).toString()
    // cookies.set(_cookiesName, ciphertext)
    localStorage.setItem(_cookiesName, ciphertext)
}

const getCookiesSession = (_cookiesName) =>  {
    // if(!cookies.get(_cookiesName)) return null
    if(!localStorage.getItem(_cookiesName)) return null

    // let ciphertext = cookies.get(_cookiesName)
    let ciphertext = localStorage.getItem(_cookiesName)
    let bytes  = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY)
    let originalText = bytes.toString(CryptoJS.enc.Utf8)

    return JSON.parse(originalText)
}

const removeCookiesSession = (_cookiesName) => {
    // cookies.remove(_cookiesName)
    localStorage.removeItem(_cookiesName)
}

const setSimpleEncryptData = (_data) => {
    let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(_data), SECRET_KEY).toString()
    return ciphertext
}

export default {
    setCookiesSession,
    getCookiesSession,
    removeCookiesSession,
    setSimpleEncryptData
}