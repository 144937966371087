import API from './../../utils/API'
import SessionManager from "../../utils/SessionManager"

export default {
    state: {
        currentUser: SessionManager.getCookiesSession('session-user') != null ? SessionManager.getCookiesSession('session-user') : null,
        currentUserPoints: SessionManager.getCookiesSession('session-user-points') != null ? SessionManager.getCookiesSession('session-user-points') : null,
        currentUserToken: SessionManager.getCookiesSession('session-user-token') != null ? SessionManager.getCookiesSession('session-user-token') : null,
        currentUserAccessType: SessionManager.getCookiesSession('session-user-access-type') != null ? SessionManager.getCookiesSession('session-user-access-type') : null,
        hasGuestSession: SessionManager.getCookiesSession('session-has-guest') != null ? SessionManager.getCookiesSession('session-has-guest') : false
    },
    getters: {
        currentUser: state => state.currentUser,
        currentUserPoints: state => state.currentUserPoints,
        currentUserToken: state => state.currentUserToken,
        currentUserAccessType: state => state.currentUserAccessType,
        hasGuestSession: state => state.hasGuestSession
    },
    mutations: {
        setUser(state, payload) {
            state.currentUser = payload
        },
        setUserPoints(state, payload) {
            state.currentUserPoints = payload
        },
        setUserToken(state, payload) {
            state.currentUserToken = payload
        },
        setUserAccessType(state, payload) {
            state.currentUserAccessType = payload
        },
        setHasGuestSession(state, payload) {
            state.hasGuestSession = payload
        },
    },
    actions: {
        async authRegister({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {
                API.post("auth/local/register", payload)
                    .then(async (res) => {
                        SessionManager.setCookiesSession('session-user-token', res.data.jwt)
                        commit('setUserToken', res.data.jwt)

                        //fetch user data
                        const res02 = await API.get(`users/${res.data.user.id}?populate[0]=role&populate[1]=user_addresses&populate[2]=user_point`)

                        SessionManager.setCookiesSession('session-user', res02.data)
                        commit('setUser', res02.data)

                        //set User Point session
                        let userPoints = null
                        if(res02.data && res02.data.user_point){
                            const resUserPoints = await API.get(`user-points/${res02.data.user_point.id}`)
                            userPoints = resUserPoints.data.data
                        }
                        SessionManager.setCookiesSession('session-user-points', userPoints)
                        commit('setUserPoints', userPoints)

                        //setCurrentUserAccessType
                        const res03 = await API.get(`user-access-types?filters[users_permissions_role_id][$eq]=${getters.currentUser.role.id}`)
                        if(res03.data.data.length > 0){
                            SessionManager.setCookiesSession('session-user-access-type', res03.data.data[0])
                            commit('setUserAccessType', res03.data.data[0])
                        }

                        //remove guest session
                        SessionManager.removeCookiesSession('session-has-guest')
                        commit('setHasGuestSession', false)

                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        async verifyLogin({
            commit, getters
        }, token){
            return new Promise((resolve, reject) => {
                API.verifyAuth(token).then(async (res) => {

                    if (res.data.confirmed){
                        SessionManager.setCookiesSession('session-user-token', token)
                        commit('setUserToken', token)
                        console.log(res.data)
                        const res02 = await API.get(`users/${res.data.id}?populate[0]=role&populate[1]=user_addresses&populate[2]=profile_image&populate[3]=user_point`)
                        console.log(res02)
                        delete res02.data.password //temp, nnti buat dkt backend strapi
                        SessionManager.setCookiesSession('session-user', res02.data)
                        commit('setUser', res02.data)

                        //set User Point session
                        let userPoints = null
                        if(res02.data && res02.data.user_point){
                            const resUserPoints = await API.get(`user-points/${res02.data.user_point.id}`)
                            userPoints = resUserPoints.data.data
                        }
                        SessionManager.setCookiesSession('session-user-points', userPoints)
                        commit('setUserPoints', userPoints)

                        //setCurrentUserAccessType
                        const res03 = await API.get(`user-access-types?filters[users_permissions_role_id][$eq]=${getters.currentUser.role.id}`)
                        if(res03.data.data.length > 0){
                            SessionManager.setCookiesSession('session-user-access-type', res03.data.data[0])
                            commit('setUserAccessType', res03.data.data[0])
                        }

                        //remove guest session
                        SessionManager.removeCookiesSession('session-has-guest')
                        commit('setHasGuestSession', false)

                        resolve(res.data)
                    }
                })
                .catch((err) => {
                    reject(err)
                });
            })
        },
        async authLogin({
            commit, getters
        }, payload) {
            return new Promise((resolve, reject) => {
                API.post("auth/local", payload)
                    .then(async (res) => {
                        if (res.data.user.confirmed) {

                            SessionManager.setCookiesSession('session-user-token', res.data.jwt)
                            commit('setUserToken', res.data.jwt)

                            //fetch user data
                            const res02 = await API.get(`users/${res.data.user.id}?populate[0]=role&populate[1]=user_addresses&populate[2]=profile_image&populate[3]=user_point`)
                            delete res02.data.password //temp, nnti buat dkt backend strapi
                            SessionManager.setCookiesSession('session-user', res02.data)
                            commit('setUser', res02.data)

                            //set User Point session
                            let userPoints = null
                            if(res02.data && res02.data.user_point){
                                const resUserPoints = await API.get(`user-points/${res02.data.user_point.id}`)
                                userPoints = resUserPoints.data.data
                            }
                            SessionManager.setCookiesSession('session-user-points', userPoints)
                            commit('setUserPoints', userPoints)

                            //setCurrentUserAccessType
                            const res03 = await API.get(`user-access-types?filters[users_permissions_role_id][$eq]=${getters.currentUser.role.id}`)
                            if(res03.data.data.length > 0){
                                SessionManager.setCookiesSession('session-user-access-type', res03.data.data[0])
                                commit('setUserAccessType', res03.data.data[0])
                            }

                            //remove guest session
                            SessionManager.removeCookiesSession('session-has-guest')
                            commit('setHasGuestSession', false)

                            resolve(res.data)
                        } else {
                            reject({ notconfirm: true })
                        }
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        async authLogout() {
            SessionManager.removeCookiesSession('session-user')
            SessionManager.removeCookiesSession('session-user-token')
            SessionManager.removeCookiesSession('session-user-access-type')
            SessionManager.removeCookiesSession('session-user-points')
            //set guest session
            SessionManager.setCookiesSession('session-has-guest', true)

            window.location.href = '/auth/login'
        },
        async clearSession(){
            SessionManager.removeCookiesSession('session-user')
            SessionManager.removeCookiesSession('session-user-token')
            SessionManager.removeCookiesSession('session-user-access-type')
            SessionManager.removeCookiesSession('session-user-points')
            //set guest session
            SessionManager.setCookiesSession('session-has-guest', true)
        },
        async updateUser({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {
                SessionManager.setCookiesSession('session-user', payload)

                commit('setUser', payload)

                resolve(payload)
            })
        },
        async updateUserPoint({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {
                SessionManager.setCookiesSession('session-user-points', payload)

                commit('setUserPoints', payload)

                resolve(payload)
            })
        },
        checkCurrentUserRole({commit, getters}){
            return new Promise(async (resolve, reject) => {
                const res = await API.get(`users/${getters.currentUser.id}?populate[0]=role`)
                resolve(res.data)
            })
        },
        checkCurrentUserIsAdmin({commit, getters}){
            return new Promise(async (resolve, reject) => {
                const res = await API.get(`users/${getters.currentUser.id}?populate[0]=role`)
                if(res.data.role.name == 'Administrator') resolve(true)
                else resolve(false)
            })
        },
        createGuestSession({commit}, payload) {
            SessionManager.setCookiesSession('session-has-guest', true)
            commit('setHasGuestSession', true)
        },
        destroyGuestSession({commit}, payload) {
            SessionManager.removeCookiesSession('session-has-guest')
            commit('setHasGuestSession', false)
        }
    }
}