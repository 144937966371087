import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar'
import VModal from 'vue-js-modal'
import VueMarkdownEditor from '@kangc/v-md-editor';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import VueAnalytics from 'vue-analytics';
import VueCookies from 'vue-cookies'

import "bootstrap-icons/font/bootstrap-icons.css"
// import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/main.scss'
import 'bootstrap'
// import AllowedIP from './utils/AllowedIP'

VueMarkdownEditor.use(vuepressTheme);
VueMarkdownEditor.lang.use('en-US', enUS);
Vue.use(VueMarkdownEditor);
Vue.use(setupCalendar, {})
Vue.component('VCalendar', Calendar)
Vue.component('VDatePicker', DatePicker)

Vue.use(VModal)
Vue.config.productionTip = false
Vue.prototype.ZoomMtg = window.ZoomMtg
Vue.prototype.ZoomMtgEmbedded = window.ZoomMtgEmbedded
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
  router
})
Vue.use(VueCookies, { 
  expires: '7d', 
  path: '', 
  domain: '', 
  secure: true, 
  sameSite: 'Lax' 
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// //allow only certain ip only.
// AllowedIP.isUserIPAllowed()
//   .then(result => {
//     if(!result){
//       window.location.href = 'https://www.tabunghaji.gov.my/'
//     }
// })