import store from '../store'

export default (to, from, next) => {
    window.addEventListener("beforeunload", () => {
        store.dispatch('destroyGuestSession')
    })

    if(store.getters.currentUser) {
        if(store.getters.currentUser.role.name == 'Administrator')
            next('/admin/dashboard')
        else next()
    }

    else if(!store.getters.currentUser && !store.getters.hasGuestSession){
        store.dispatch('createGuestSession')
        next('/auth/login')
    }

    else next()
}